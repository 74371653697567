import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BedIcon from '@mui/icons-material/Bed';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Sidebar = () => {

  const navstate = useSelector(state => state.global.navstate);

  return (
    <div className={'sidebar ' + navstate}>
      <div className="inner">
        <div className="top">
          <span className="logo"></span>
        </div>
        <div className="center">
          <ul>
            <li><NavLink to='/'><DashboardIcon className='icon' /><span>Dashboard</span></NavLink></li>
            <li><NavLink to='/unterkuenfte'><BedIcon className='icon' /><span>Unterkünfte</span></NavLink></li>
          </ul>


          <br />
          <br />
          <br />
          <p className='title'>Tests</p>
          <ul>
            <li><NavLink to='/mapmarkertest'><LocationOnIcon className='icon' /><span>Map-Marker-Test</span></NavLink></li>
            <li><NavLink to='/mapclustertest'><LocationOnIcon className='icon' /><span>Map-Cluster-Test</span></NavLink></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar 