import { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as GlobalStore from './store/global/actions';
import Sidebar from './theme/Sidebar'
import Topbar from './theme/Topbar'
import ErrorBoundary from './components/ErrorBoundary';
import Login from './pages/frontend/Login';
import Register from './pages/frontend/Register';
import FrontendError404 from './pages/frontend/FrontendError404';
import Password from './pages/frontend/Password';
import NoCompleteUserdata from './pages/userbackend/preforms/NoCompleteUserdata';
import AdminTopbar from './theme/admin/AdminTopbar';
import AdminSidebar from './theme/admin/AdminSidebar';
import HelperService from './services/helper.service';

const FrontendCSS = lazy(() => import('./components/cssloader/FrontendCSS'));
const BackendCSS = lazy(() => import('./components/cssloader/BackendCSS'));

// ADMIN-BACKEND
const AdminHome = lazy(() => import('./admin/pages/Home'));
const Lessors = lazy(() => import('./admin/pages/Lessors'));
const EditLessor = lazy(() => import('./admin/pages/subpages/EditLessor'));
const ObjectEquipment = lazy(() => import('./admin/pages/ObjectEquipment'));
const NewObjectEquipment = lazy(() => import('./admin/pages/subpages/NewObjectEquipment'));
const EditObjectEquipment = lazy(() => import('./admin/pages/subpages/EditObjectEquipment'));
const ObjectEquipmentCycles = lazy(() => import('./admin/pages/ObjectEquipmentCycles'));
const NewEquipmentCycle = lazy(() => import('./admin/pages/subpages/NewEquipmentCycle'));
const EditEquipmentCycle = lazy(() => import('./admin/pages/subpages/EditEquipmentCycle'));
const ObjectIncidentalCosts = lazy(() => import('./admin/pages/ObjectIncidentalCosts'));
const NewIncidentalCost = lazy(() => import('./admin/pages/subpages/NewIncidentalCost'));
const EditIncidentalCost = lazy(() => import('./admin/pages/subpages/EditIncidentalCost'));
const ObjectBenefitsCosts = lazy(() => import('./admin/pages/ObjectBenefitsCosts'));
const NewBenefitsCost = lazy(() => import('./admin/pages/subpages/NewBenefitsCost'));
const EditBenefitsCost = lazy(() => import('./admin/pages/subpages/EditBenefitsCost'));
const ObjectLocations = lazy(() => import('./admin/pages/ObjectLocations'));
const NewLocation = lazy(() => import('./admin/pages/subpages/NewLocation'));
const EditLocation = lazy(() => import('./admin/pages/subpages/EditLocation'));
const ObjectPropertyTypes = lazy(() => import('./admin/pages/ObjectPropertyTypes'));
const NewPropertyType = lazy(() => import('./admin/pages/subpages/NewPropertyType'));
const EditPropertyType = lazy(() => import('./admin/pages/subpages/EditPropertyType'));


// USER-BACKEND
const Home = lazy(() => import('./pages/userbackend/Home'));
const UserProfile = lazy(() => import('./pages/userbackend/UserProfile'));
const Objects = lazy(() => import('./pages/userbackend/Objects'));
const NewObject = lazy(() => import('./pages/userbackend/subpages/object/NewObject'));
const EditObjectGeneralData = lazy(() => import('./pages/userbackend/subpages/object/EditObjectGeneralData'));
const EditObjectLocationData = lazy(() => import('./pages/userbackend/subpages/object/EditObjectLocationData'));
const EditObjectEquipmentData = lazy(() => import('./pages/userbackend/subpages/object/EditObjectEquipmentData'));
const EditObjectPictures = lazy(() => import('./pages/userbackend/subpages/object/EditObjectPictures'));
const EditObjectPrices = lazy(() => import('./pages/userbackend/subpages/object/EditObjectPrices'));
const EditObjectOccupancies = lazy(() => import('./pages/userbackend/subpages/object/EditObjectOccupancies'));
const MapMarkerTest = lazy(() => import('./pages/userbackend/MapMarkerTest'));
const MapClusterTest = lazy(() => import('./pages/userbackend/MapClusterTest'));
const Error404 = lazy(() => import('./pages/userbackend/Error404'));

const App = () => {

  const [pageLocation, setPageLocation] = useState();

  const dispatch = useDispatch();

  const darkmode = useSelector(state => state.global.darkmode);
  const navstate = useSelector(state => state.global.navstate);
  const user = useSelector(state => state.global.user);
  const adminuser = useSelector(state => state.global.adminuser);
  const userstate = useSelector(state => state.global.userstate);
  const ref = useRef(null);

  const resizingDelayTimer = useRef(null);

  function getClassBySize(size) {
    if (size > 1679) {
      return 'xxl'
    }
    else if (size > 1399 && size < 1680) {
      return 'xl'
    }
    else if (size > 1199 && size < 1400) {
      return 'lg'
    }
    else if (size > 991 && size < 1200) {
      return 'md'
    }
    else if (size > 767 && size < 992) {
      return 'sm'
    }
    else if (size > 0 && size < 768) {
      return 'xs'
    }
    else {
      return 'xxl'
    }
  }

  useEffect(() => {
    const element = ref?.current;
    if (!element) return;
    let startsizeclass = getClassBySize(element.offsetWidth);
    const observer = new ResizeObserver(() => {
      clearTimeout(resizingDelayTimer.current);
      resizingDelayTimer.current = setTimeout(() => {
        element.setAttribute("class", "app_wrapper");
        let sizeClass = getClassBySize(element.offsetWidth);
        element.classList.add(sizeClass);
        if (sizeClass === 'xs' && user) {
          dispatch(GlobalStore.setNavState('closed'))
        }
        else {
          if (startsizeclass !== sizeClass) {
            dispatch(GlobalStore.setNavState('opened'))
          }
        }
      }, 1)
    });
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [ref, dispatch, user])


  // REGISTER / LOGIN
  if (!user) {
    return (
      <div className="app_wrapper" ref={ref}>
        <Suspense>
          <FrontendCSS>
            <BrowserRouter>
              <ErrorBoundary pageLocation={pageLocation} setPageLocation={setPageLocation} >
                <Routes>
                  <Route path='/' element={<Login />}></Route>
                  <Route path='/registrierung' element={<Register />}></Route>
                  <Route path='/passwort-vergessen' element={<Password />}></Route>
                  <Route path='*' element={<FrontendError404 />}></Route>
                </Routes>
              </ErrorBoundary>
            </BrowserRouter>
          </FrontendCSS>
        </Suspense>
      </div>
    )
  }


  // ADMIN-BACKEND
  if (adminuser && !HelperService.checkSwitchAdminToUser(user, adminuser)) {
    return (
      <div className="app_wrapper" ref={ref}>
        <Suspense>
          <BackendCSS>
            <BrowserRouter>
              <div className={"app" + (darkmode ? ' darkmode' : '')} >
                <AdminSidebar />
                <div className={'mainholder ' + navstate}>
                  <AdminTopbar />
                  <ErrorBoundary pageLocation={pageLocation} setPageLocation={setPageLocation} >
                    <Routes>
                      <Route path='/' element={<AdminHome />}></Route>
                      <Route path='/vermieter' element={<Lessors />}></Route>
                      <Route path='/vermieter/bearbeiten/:id' element={<EditLessor />}></Route>
                      <Route path='/ausstattungsmerkmale' element={<ObjectEquipment />}></Route>
                      <Route path='/ausstattungsmerkmale/neu' element={<NewObjectEquipment />}></Route>
                      <Route path='/ausstattungsmerkmale/bearbeiten/:id' element={<EditObjectEquipment />}></Route>
                      <Route path='/abrechnungsoptionen' element={<ObjectEquipmentCycles />}></Route>
                      <Route path='/abrechnungsoptionen/neu' element={<NewEquipmentCycle />}></Route>
                      <Route path='/abrechnungsoptionen/bearbeiten/:id' element={<EditEquipmentCycle />}></Route>

                      <Route path='/nebenkostenoptionen' element={<ObjectIncidentalCosts />}></Route>
                      <Route path='/nebenkostenoptionen/neu' element={<NewIncidentalCost />}></Route>
                      <Route path='/nebenkostenoptionen/bearbeiten/:id' element={<EditIncidentalCost />}></Route>

                      <Route path='/zusatzkostenoptionen' element={<ObjectBenefitsCosts />}></Route>
                      <Route path='/zusatzkostenoptionen/neu' element={<NewBenefitsCost />}></Route>
                      <Route path='/zusatzkostenoptionen/bearbeiten/:id' element={<EditBenefitsCost />}></Route>

                      <Route path='/lagebeschreibungen' element={<ObjectLocations />}></Route>
                      <Route path='/lagebeschreibungen/neu' element={<NewLocation />}></Route>
                      <Route path='/lagebeschreibungen/bearbeiten/:id' element={<EditLocation />}></Route>

                      <Route path='/objektarten' element={<ObjectPropertyTypes />}></Route>
                      <Route path='/objektarten/neu' element={<NewPropertyType />}></Route>
                      <Route path='/objektarten/bearbeiten/:id' element={<EditPropertyType />}></Route>
                      <Route path='*' element={<Error404 />}></Route>
                    </Routes>
                  </ErrorBoundary>
                </div>
              </div>
            </BrowserRouter>
          </BackendCSS>
        </Suspense>
      </div>
    )
  }

  // USER INCOMPLETE
  if (user) {
    if (userstate === 'incomplete') {
      return (
        <div className="app_wrapper" ref={ref}>
          <Suspense>
            <FrontendCSS>
              <BrowserRouter>
                <ErrorBoundary pageLocation={pageLocation} setPageLocation={setPageLocation} >
                  <Routes>
                    <Route path='/' element={<NoCompleteUserdata />}></Route>
                    <Route path='*' element={<FrontendError404 />}></Route>
                  </Routes>
                </ErrorBoundary>
              </BrowserRouter>
            </FrontendCSS>
          </Suspense>
        </div>
      )
    }
  }


  // USER-BACKEND
  return (
    <div className="app_wrapper" ref={ref}>
      <Suspense>
        <BackendCSS>
          <BrowserRouter>
            <div className={"app" + (darkmode ? ' darkmode' : '')} >
              <Sidebar />
              <div className={'mainholder ' + navstate}>
                <Topbar />
                <ErrorBoundary pageLocation={pageLocation} setPageLocation={setPageLocation} >
                  <Routes>
                    <Route path='/' element={<Home />}></Route>
                    <Route path='/mein-konto' element={<UserProfile />}></Route>
                    <Route path='/unterkuenfte' element={<Objects />}></Route>
                    <Route path='/unterkuenfte/neu' element={<NewObject />}></Route>
                    <Route path='/unterkuenfte/:id/allgemeine-angaben' element={<EditObjectGeneralData />}></Route>
                    <Route path='/unterkuenfte/:id/lage' element={<EditObjectLocationData />}></Route>
                    <Route path='/unterkuenfte/:id/ausstattung' element={<EditObjectEquipmentData />}></Route>
                    <Route path='/unterkuenfte/:id/bilder' element={<EditObjectPictures />}></Route>
                    <Route path='/unterkuenfte/:id/preise' element={<EditObjectPrices />}></Route>
                    <Route path='/unterkuenfte/:id/belegungskalender' element={<EditObjectOccupancies />}></Route>
                    <Route path='/mapmarkertest' element={<MapMarkerTest />}></Route>
                    <Route path='/mapclustertest' element={<MapClusterTest />}></Route>
                    <Route path='*' element={<Error404 />}></Route>
                  </Routes>
                </ErrorBoundary>
              </div>
            </div>
          </BrowserRouter>
        </BackendCSS>
      </Suspense>
    </div>
  );

}

export default App;
