import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { ImageField, Input, Select } from './add/Components';
import ApiService from '../../services/api.service';
import HelperService from '../../services/helper.service';
import * as GlobalStore from '../../store/global/actions';
import LoadingSpinner from '../LoadingSpinner';

const FormUserData = ({ section }) => {

    const [loaded, setLoaded] = useState(false);

    const [error, setError] = useState(null);
    if (error && error !== '404') {
        throw error;
    }

    const methods = useForm();
    const { register, handleSubmit, formState: { errors }, clearErrors, setValue, getValues, reset } = useForm({
        defaultValues: {
            logo: ''
        }
    });

    const dispatch = useDispatch();

    const [response, setResponse] = useState();
    const [loadReady, setLoadReady] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const user = useSelector(state => state.global.user);
    const usertokendata = HelperService.getUserData(user);

    useEffect(() => {
        if (!loaded && !submitted) {
            setLoaded(true);
            const API_URL = global.config.express.url + '/user/get';
            const API_DATA = {
                'userId': usertokendata.userId
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof (response.apierror) !== 'undefined') {
                    setError(response.apierror);
                }
                else {
                    let data = response.result;
                    setResponse(response);
                    reset({
                        picture: data.picture,
                        gender: data.gender,
                        firstname: data.firstname,
                        lastname: data.lastname,
                        email: data.email,
                        phone: data.phone,
                        street: data.address.street,
                        postalcode: data.address.postalcode,
                        city: data.address.city
                    })
                    setTimeout(() => {
                        setLoadReady(true);
                    }, 500);

                }
            }).catch((error) => {
                setError('NO_CONNECTION')
            });
        }
    }, [usertokendata, loaded, reset, getValues, submitted])

    const onSubmit = (data) => {
        setSubmitted(true);
        const API_URL = global.config.express.url + '/user/set';
        const API_DATA = {
            'id': usertokendata.userId,
            'picture': data.picture,
            'gender': data.gender ? data.gender : response.result.gender,
            'email': data.email ? data.email : response.result.email,
            'firstname': data.firstname ? data.firstname : response.result.firstname,
            'lastname': data.lastname ? data.lastname : response.result.lastname,
            'phone': data.phone ? data.phone : null,
            'address': {
                'postalcode': data.postalcode,
                'city': data.city,
                'street': data.street
            }
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (typeof (response.apierror) !== 'undefined') {
                setError(response.apierror);
            }
            else {
                let newdata = user;
                newdata.data = response.result;
                dispatch(GlobalStore.setUser(newdata));
                dispatch(GlobalStore.setUserState('complete'));
                window.location.reload()
            }
        }).catch((error) => {
            setError('NO_CONNECTION')
        });
    };

    let firstlogin = section === 'firstlogin' ? true : false;

    let cont;
    cont = loadReady ?
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className="form-add" onKeyDown={(e) => { HelperService.handleFormKeyDown(e) }}>
                <div className="profile">
                    <div className='userimage'>
                        <ImageField register={register} clearErrors={clearErrors} name="picture" label="" errors={errors} width="500" height="500" placeholder freepos round setValue={setValue} data={response?.result?.picture ? response.result.picture : ''} />
                    </div>
                    <div className='userform'>
                        <Select
                            register={register}
                            options={{
                                '': 'bitte auswählen',
                                'male': 'Herr',
                                'female': 'Frau',
                                'divers': 'keine Angabe'
                            }}
                            name="gender"
                            label="Anrede"
                            size="third-left"
                            required
                            readonly={firstlogin}
                            errors={errors}
                        />
                        <Input register={register} type="text" name="firstname" label="Vorname" size="third-middle" required readonly={firstlogin} errors={errors}></Input>
                        <Input register={register} type="text" name="lastname" label="Nachname" size="third-right" required readonly={firstlogin} errors={errors}></Input>
                        <Input register={register} type="email" name="email" label="E-Mail-Adresse" size="half-left" required readonly={firstlogin} errors={errors} pattern='email'></Input>
                        <Input register={register} type="number" name="phone" label="Telefonnummer" size="half-right" errors={errors} pattern='phone'></Input>
                        <Input register={register} type="text" name="street" label="Straße / Hausnummer" size="third-left" required errors={errors}></Input>
                        <Input register={register} type="number" name="postalcode" label="PLZ" size="third-middle" maxLength={5} required errors={errors} pattern='zipcode'></Input>
                        <Input register={register} type="text" name="city" label="Ort" size="third-right" required errors={errors}></Input>
                    </div>
                </div>
                <div className="cpart">
                    <div className="submit_container">
                        <div className="formfieldholder">
                            <button type="submit" className={"submit" + (submitted ? ' load' : '')} disabled={submitted}>speichern</button>
                        </div>
                    </div>
                </div>
            </form>
        </FormProvider> :
        section !== 'firstlogin' && <LoadingSpinner />
        ;

    if (section === 'firstlogin' && loadReady) {
        return (
            <div className="fe-wrapper">
                <div className='box large'>
                    <span className='logo'></span>
                    <h3>Hallo {response.result.firstname},</h3>
                    <p>wir freuen uns, dass du deine Unterkünfte bei uns präsentieren möchtest.<br />
                        Damit du gleich loslegen kannst, vervollständige bitte noch schnell deine Daten.</p>
                    {cont}
                </div>
            </div>
        )
    }
    else {
        return cont
    }


}

export default FormUserData