const initialState = {
    navstate: 'opened',
    user: false,
    saved: false,
    topmessage: false
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_APIERROR': {
            return {
                ...state,
                apierror: action.apierror,
            };
        }
        case 'SET_USER': {
            return {
                ...state,
                user: action.user,
            };
        }
        case 'SET_USERSTATE': {
            return {
                ...state,
                userstate: action.userstate,
            };
        }
        case 'SET_NAVSTATE': {
            return {
                ...state,
                navstate: action.navstate,
            };
        }
        case 'SET_SAVED': {
            return {
                ...state,
                saved: action.saved,
            };
        }
        case 'SET_TOPMESSAGE': {
            return {
                ...state,
                topmessage: action.topmessage,
            };
        }
        case 'SET_ADMINUSER': {
            return {
                ...state,
                adminuser: action.adminuser,
            };
        }
        default: {
            return state;
        }
    }
};

export default Reducer;
